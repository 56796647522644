:root {
    --pageBg: #eeeeee;
    --pageText: black;
    --buttonBg: #00a4eb;
    --buttonText: black;
    --buttonDisabled: #999999;
    --buttonDisabledText: #444444;
    --panelBg: #cccccc;
    --panelTableLine: #aaaaaa;
    --panelText: black;
}

html, body {
    background-color: var(--pageBg);
    color: var(--pageText);
    height: 100%;
}

@media (max-width: 1100px) {
    html {
        font-size: 1.818vw;
    }

    div.responsive {
        display: grid;
        grid-template-columns: 0 auto 0;
    }

    div.full-width {
        width: 100%;
    }
}

@media (min-width: 1100px) {
    html {
        font-size: 20px;
    }

    div.responsive {
        display: grid;
        grid-template-columns: auto minmax(auto, 1100px) auto;
    }

    div.full-width {
        width: 1100px;
    }
}

/* mobile */
@media (max-width: 600px) {
    input[type=text], input[type=password], input[type=email] {
        border: 0.6rem solid var(--buttonBg);
        padding: 0.4rem 0.6rem;
        text-decoration: none;
        display: inline-block;
        border-radius: 0.3rem;
        margin: 1.5rem;
        font-size: 4rem;
        background-color: #DDDDDD;
    }
}

/* desktop */
@media (min-width: 600px) {
    input[type=text], input[type=password], input[type=email] {
        border: 0.3rem solid var(--buttonBg);
        padding: 0.2rem 0.4rem;
        text-decoration: none;
        display: inline-block;
        border-radius: 0.1rem;
        margin: 0.3rem;
        font-size: 1rem;
        background-color: #DDDDDD;
    }
}

/* mobile */
@media (max-width: 600px) {
    button {
        background-color: var(--buttonBg);
        border: none;
        color: black;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border-radius: 0.3rem;
        margin: 1rem;
        font-size: 4rem;
        height: 6rem;
        padding: 0.5rem;
        cursor: pointer;
    }
}

/* desktop */
@media (min-width: 600px) {
    button {
        background-color: var(--buttonBg);
        border: none;
        color: black;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border-radius: 0.1rem;
        margin: 0.3rem;
        font-size: 1rem;
        height: 1.8rem;
        cursor: pointer;
    }
}

/* mobile */
@media (max-width: 600px) {
    html {
        font-size: 2vw;
    }
}

/* desktop */
@media (min-width: 600px) {

}


/* common for both mobile and desktop*/
ul {
    padding-left: 0;
}

div.root {
    height: 100%;
    position: relative
}

button:disabled {
    color: var(--buttonDisabledText);
    background-color: var(--buttonDisabled);
}

a {
    display: inline-block;
    color: var(--buttonBg);
    text-decoration: none;
}


table.invisible tr {
    background-color: var(--pageBg);
    color: var(--pageText);
}

table.white-bordered {
    border: none;
    border-collapse: collapse;
}

table.white-bordered td {
    padding: 0.2rem;
    border-left: 2px solid var(--panelTableLine);
    text-align: center;
}

table.white-bordered th {
    padding: 0.2rem;
}

table.white-bordered td:first-child, table.white-bordered th:first-child {
    border-left: none;
}

table.white-bordered tbody tr {
    border-bottom: 2px solid var(--panelTableLine);
}

table.white-bordered tbody tr:last-child {
    border-bottom: none;
}

/* desktop */
@media (min-width: 600px) {
    table tr th.rotated {
        height: 2rem;
        white-space: nowrap;
        position: relative;
    }

    table tr th.rotated > div {
        position: absolute;
        transform: /* Magic Numbers */ translate(-0.8rem, 0) /* 45 is really 360 - 45 */ rotate(45deg);
        width: 6rem;
        height: 1rem;
        border-bottom: 2px solid var(--panelTableLine);
        bottom: 1.86rem;
        left: -3.96rem;
    }

    table tr th.rotated > div > span {
        position: absolute;
        left: 0rem;
        bottom: 0.3rem;
        width: 6rem;
        text-align: right;
    }
}

/* mobile */
@media (max-width: 600px) {
    table tr th.rotated {
        height: 2rem;
        white-space: nowrap;
        position: relative;
    }

    table tr th.rotated > div {
        position: absolute;
        transform: /* Magic Numbers */ translate(-0.8rem, 0) /* 45 is really 360 - 45 */ rotate(45deg);
        width: 10rem;
        height: 1rem;
        border-bottom: 2px solid var(--panelTableLine);
        bottom: 3.35rem;
        left: -7.3rem;
    }

    table tr th.rotated > div > span {
        position: absolute;
        left: 0;
        bottom: 0.2rem;
        width: 10rem;
        text-align: right;
    }
}

.pulsate-red {
    color: #FF3333;
    -webkit-animation: pulse linear 1s infinite;
    animation: pulse linear 1s infinite;
}

.pulsate-white {
    color: #FFFFFF;
    -webkit-animation: pulse linear 1s infinite;
    animation: pulse linear 1s infinite;
}

@-webkit-keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .01;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .01;
    }
    100% {
        opacity: 1;
    }
}








